import TemplateContents from './TemplateContents';
import Carousel from './Carousel';

export default class extends Carousel{
    constructor(param){
        super(param);
    }

    setVars() {
        super.setVars();

        this.prevFrameSwipeX = 0;
    }

    //dom設定
    setDom() {
        super.setDom();

        this.carouselWrap = this.target.querySelector('.carousel_wrap');

        this.barBase = this.target.querySelector('.carousel_ui .bar .base');
        this.bar = this.target.querySelector('.carousel_ui .bar .progress');
    }

    startSwipeHandler(event) {
        this.resizeHandler();

        if (!this.enabled) return;
        let x;
        let hasTouch = event.type.indexOf('touch') == 0;

        this.swipeIntervalCnt = 0;
        this.swipeInterval = setInterval(() => {
            this.swipeIntervalCnt++;
        }, 1000 / 30);

        if (hasTouch) {
            let touches = event.changedTouches[0];
            this.swipeStartAbsoluteX = touches.clientX;
            let multiple;
            if(this.sw >= this.pack.BP) multiple = 2;
            else {
                multiple = 1;
            }
            x = touches.clientX * multiple;
        } else {
            event.preventDefault();
            this.swipeStartAbsoluteX = event.clientX;
            x = event.clientX * 2;
        }

        this.swipeStartPoint.x = x - this.pastSwipeX;

        document.addEventListener(this.swipeStopEvent, this.bindStopSwipeHandler);
        document.addEventListener(this.swipingEvent, this.bindSwipingHandler);
    }

    swipingHandler(event) {
        if (!this.enabled) return;
        let x;
        let hasTouch = event.type.indexOf('touch') == 0;
        let dr = .9;

        let minX;
        if (this.sw >= this.pack.BP) minX = -this.getCarouselWidth() + this.sw - this.minXAdjustPC;
        else minX = -this.getCarouselWidth() + this.sw - this.minXAdjustSP;

        if (hasTouch) {
            // event.preventDefault();
            let touches = event.changedTouches[0];
            let multiple;
            if(this.sw >= this.pack.BP) multiple = 2;
            else {
                multiple = 1;
                dr = .1;
            }
            x = touches.clientX * multiple;
        } else {
            event.preventDefault();
            x = event.clientX * 2;
        }

        this.targetSwipeX = (x - this.swipeStartPoint.x);

        if (this.targetSwipeX <= minX) this.targetSwipeX = minX;
        else if (this.targetSwipeX >= 0) this.targetSwipeX = 0;

        TweenMax.killTweensOf(this);
        TweenMax.to(this, dr, {
            swipeX: this.targetSwipeX, onUpdate: () => {
                this.carousel.style.transform = 'translate3d(' + this.swipeX + 'px, 0, 0)';
            }, ease: Quart.easeOut
        });

        this.updateImages();
        if(this.fader) this.updateUI();
    }

    stopSwipeHandler(event) {
        if (!this.enabled) return;
        let x;
        let hasTouch = event.type.indexOf('touch') == 0;

        if (hasTouch) {
            let touches = event.changedTouches[0];
            let multiple;
            if(this.sw >= this.pack.BP) multiple = 2;
            else multiple = 1;

            x = touches.clientX * multiple;
        } else {
            x = event.clientX * 2;
        }

        //スワイプしたかどうか
        if (this.swipeStartAbsoluteX === x) this.swiped = false;
        else {
            this.swiped = true;
            if(hasTouch && this.sw < this.pack.BP){
                if (this.swipeStartAbsoluteX > x) this.clickNextHandler();
                else this.clickPrevHandler();
            }
        }

        this.pastSwipeX = this.targetSwipeX;
        clearInterval(this.swipeInterval);
        document.removeEventListener(this.swipeStopEvent, this.bindStopSwipeHandler);
        document.removeEventListener(this.swipingEvent, this.bindSwipingHandler);
    }

    //30fps
    enterframeThinOut() {
        let contentsMoveW;
        if (this.sw >= this.pack.BP) contentsMoveW = this.getCarouselWidth() - this.sw + this.minXAdjustPC;
        else contentsMoveW = this.getCarouselWidth() - this.sw + this.minXAdjustSP;
        let per = Math.abs(this.swipeX) / contentsMoveW;

        this.bar.style.transform = 'scaleX(' + per + ')';

        let difSwipeX = this.swipeX - this.prevFrameSwipeX;
        let skewX = difSwipeX * .08;
        let limit = 5;
        if(skewX >= limit)skewX = limit;
        else if(skewX <= -limit)  skewX = -limit;

        this.carouselWrap.style.transform = 'skewX(' + skewX + 'deg)';
        this.prevFrameSwipeX = this.swipeX;
    }
}