import TemplateContents from './TemplateContents';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();

        this.isOpenMenu = false;
    }

    setDom(){
        super.setDom();

        this.headerDom = document.querySelector('header');
        this.menu = document.querySelector('header .menu');
        this.menuHitArea = document.querySelector('header .menu nav .hit_area');
        this.hamburger = document.querySelector('header .hamburger');
        this.menuBtns = document.querySelectorAll('header .menu ul li');
        this.cpDom = document.querySelector('header .cp.pc');
        this.logo = document.querySelector('header .logo');
    }

    initEvents(){
        super.initEvents();

        this.hamburger.addEventListener('click', this.clickHamburgerHandler.bind(this));
        this.logo.addEventListener('click', this.clickLogoHandler.bind(this));


        this.menuHitArea.addEventListener('click', ()=>{
            this.close();
        });

        for( let i = 0, len = this.menuBtns.length; i < len; i++ ){
            let item = this.menuBtns[i];
            item.addEventListener('click', (event)=>{
                this.close();
                this.dispatchEvent('select', this, i);
            });
        }
    }

    clickLogoHandler(event){
        this.dispatchEvent('select', this, 0);
    }

    clickHamburgerHandler(event){
        if(this.isOpenMenu){
            this.close();
        }else{
            this.open();
        }
    }

    open(){
        this.menu.classList.add('open');
        this.hamburger.classList.add('close');
        document.body.classList.add('blur');
        this.isOpenMenu = !this.isOpenMenu;
    }

    close(){
        this.menu.classList.remove('open');
        this.hamburger.classList.remove('close');
        document.body.classList.remove('blur');
        this.isOpenMenu = !this.isOpenMenu;
    }

    show(){
        this.headerDom.classList.add('show');
    }

    setWhite(bool){
        if(bool) this.headerDom.classList.add('white');
        else this.headerDom.classList.remove('white');
    }

    setWhite2(bool){
        if(bool) this.headerDom.classList.add('white2');
        else this.headerDom.classList.remove('white2');
    }

    scrollHandler(){

    }


    enterframe(){

    }

    enterframeThinOut(){

    }

    executeResize() {
        super.executeResize();

        let lineLeft = 15 * this.sw / 1600;
        let cpMarginLeft = 15 * this.sw / 1600;
        let cpWidth = this.cpDom.clientWidth;
        let cpRight = 31 * this.sw / 1600;
        let variableLineWidth = this.sw - lineLeft - cpMarginLeft - cpWidth - cpRight;
        
        document.documentElement.style.setProperty('--variable-line-width', variableLineWidth + 'px');
    }
}