import TemplateContents from './TemplateContents';

import Header from './Header';
import Footer from './Footer';
import Top from './Top';

export default class extends TemplateContents{
    constructor(param){
        super(param);

        this.pack.common = this;
    }

    init() {
        super.init();

        this.pack.header = new Header(this.pack);
        this.pack.footer = new Footer(this.pack);
    }

    addScrollTarget(target){
        this.scrollTarget = target;
    }

    removeScrollTarget(){
        this.scrollTarget = undefined;
    }

    addEnterframeTarget(target){
        this.enterframeTarget = target;
    }

    removeEnterframeTarget(){
        this.enterframeTarget = undefined;
    }

    setVars(){
        super.setVars();
        this.enterframeID;
        this.enterframeIgnoreCnt = 0;

        this.pastPageYOffset = 0;
        this.scrollYOffset = 0;
        this.pastScrollYOffset = 0;

        this.UPDATE_LOAD_COEFF = 0.5;
        this.FPS = 30;
        this.targetInterval = 1000 / this.FPS;
        this.prevTime = Date.now() - this.targetInterval;
    }

    setDom(){
        super.setDom();
        this.scrollWrapper = document.querySelector('.scroll-wrapper');
        this.barbaContainer = document.querySelector('.barba-container');
    }

    initEvents(){
        super.initEvents();

        this.resizeEvent = navigator.userAgent.match(/(iPhone|iPod|iPad)/) ? 'orientationchange' : 'resize';

        window.addEventListener("load", this.loadedHandler.bind(this));
        window.addEventListener("DOMContentLoaded", this.DOMContentloadedHandler.bind(this));

        window.addEventListener(this.resizeEvent, this.resizeHandler.bind(this), false);
        window.addEventListener('scroll', this.scrollHandler.bind(this),{passive: false});


        this.resizeHandler();
        this.enterframe();
    }

    DOMContentloadedHandler(event){

    }

    loadedHandler(event){
        TweenMax.delayedCall(.3, ()=>{
            window.scrollTo(0, 0);
            this.initContainer();
        });
    }

    initContainer() {
        window.scrollTo(0, 0);

        let namespace = this.barbaContainer.dataset.namespace;
        this.pack.current = namespace;

        if (namespace === 'top') {
            this.pack.top = new Top(this.pack);
            // this.pack.top.start();
        }

        this.setCurrent();
    }

    setCurrent(){
        // this.pack.header.setCurrent();
    }

    start(){

    }

    scrollHandler(){

    }


    enterframe(){
        this.enterframeID = window.requestAnimationFrame(this.enterframe.bind(this));

        if(this.enterframeTarget && this.enterframeTarget.enterframe) this.enterframeTarget.enterframe();

        // this.smoothScroll();

        let currentTime = Date.now();
        let updated = false;
        while (currentTime - this.prevTime > this.targetInterval) {

            updated = true;
            this.prevTime += this.targetInterval;
            const now = Date.now();
            const updateTime = now - currentTime;
            if (updateTime > this.targetInterval * this.UPDATE_LOAD_COEFF) {
                // overloaded
                if (this.prevTime < now - this.targetInterval) {
                    // do not accumulate too much
                    this.prevTime = now - this.targetInterval;
                }
                break;
            }
        }

        if (updated) {
            if(this.enterframeTarget) this.enterframeTarget.enterframeThinOut();
        }

        if(this.enterframeIgnoreCnt % 90 === 0){
            //間引き処理(1 execution per 90 frames)
            this.resizeHandler();
        }

        //前回のスクロール位置（全ての処理が終わってから代入）
        this.pastPageYOffset = this.pack.pastPageYOffset = this.pack.pageYOffset;

        this.enterframeIgnoreCnt++;
    }

    enterframeThinOut(){

    }

    executeResize() {
        super.executeResize();
        if(this.pack.header) this.pack.header.executeResize();
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--variable-vh', `${vh}px`);
        document.documentElement.style.setProperty('--variable-shh', `${this.shh}px`);
    }
}