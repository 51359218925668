import TemplateContents from './TemplateContents';
import TopHero from './TopHero';
import Carousel from './CSRCarousel';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        this.hero = new TopHero(this.pack);
        this.hero.addEventListener('loaded', this.onLoadHero.bind(this));

        this.carousel = new Carousel(this.pack);
        this.carousel.setTarget(this.section6);
        this.carousel.setMarginRightPC(true, this.pack.getPCVW(65));
        this.carousel.setMarginRightSP(true, this.pack.getSPVW(21));
        this.carousel.setAdjustMinXPC(true, this.pack.getPCVW(240));    　　  //カルーセルの絶対値のX軸を設定
        this.carousel.setAdjustMinXSP(true, this.pack.getSPVW(19.5));         //カルーセルの絶対値のX軸を設定

        // this.pack.common.addScrollTarget(this);
        this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();
        this.lockScroll = true;
        this.startTouchStartY = 0;
        // this.currentTouchY = 0;

        this.pastContents = 0;
        this.currentContents = 0;
        this.contentsLen = 6;
        this.enabledScroll = false;
    }

    setDom(){
        super.setDom();

        this.bg = document.querySelector('.bg');
        this.intro = document.querySelector('.intro');
        this.cover = document.querySelector('.cover');
        this.heroImgContainer = document.querySelector('main.top .hero .imgContainer');
        this.section1 = document.querySelector('.section1');
        this.section2 = document.querySelector('.section2');
        this.section3 = document.querySelector('.section3');
        this.section4 = document.querySelector('.section4');
        this.section5 = document.querySelector('.section5');
        this.section6 = document.querySelector('.section6');

        this.section3UL = document.querySelector('.section3 ul');
        this.section4UL = document.querySelector('.section4 ul');

        for( let i = 1, len = 6; i <= len; i++ ){
            let section = document.querySelector('.section' + i);
            let spans = section.querySelectorAll('h2 .tit span, h2 .sub_tit span');
            for( let j = 0; j < spans.length; j++ ){
                let span = spans[j];
                span.style.transitionDelay = .4 + Math.random() * .4 + 's';
            }
        }
    }

    initEvents(){
        super.initEvents();

        this.mousewheelEvent = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';
        this.bindWheelHandler = this.wheelHandler.bind(this);
        this.bindTouchStartHandler = this.touchStartHandler.bind(this);
        this.bindTouchMoveHandler = this.touchMoveHandler.bind(this);
        // this.bindTouchEndHandler = this.touchEndHandler.bind(this);

        document.addEventListener(this.mousewheelEvent, this.bindWheelHandler, {passive: false});
        document.addEventListener('touchstart', this.bindTouchStartHandler, {passive: false});
        document.addEventListener('touchmove', this.bindTouchMoveHandler, {passive: false, cancelable: true});
        // document.addEventListener('touchend', this.bindTouchEndHandler, {passive: false});

        this.pack.header.addEventListener('select', (id) =>{
            this.externalTransit(id);
        });


        this.pack.footer.addEventListener('select', (id) =>{
            this.externalTransit(id);
        });
    }

    externalTransit(id){
        this.lockScroll = true;
        this.enabledScroll = false;
        this.pastContents = undefined;
        this.currentContents = id;
        if(this.currentContents === 3) this.currentContents = 4;
        else if(this.currentContents === 4) this.currentContents = 5;
        this.transit(this.currentContents);
    }

    wheelHandler(event) {
        if(this.pack.header.isOpenMenu) {
            event.preventDefault();
            return;
        }

        if (!event) event = window.event; //for legacy IE
        let delta = event.deltaY ? (event.deltaY) : event.wheelDelta ? event.wheelDelta : -(event.detail);

        let currentTime = Date.now();
        let currentDelta = Math.abs(delta);
        let isCancel = false;

        if(currentDelta <= this.prevWheelDelta){
            if(currentTime - this.prevWheelTime < 100 ) isCancel = true;
        }

        this.prevWheelTime = currentTime;
        this.prevWheelDelta = currentDelta;

        if (this.lockScroll) {
            event.preventDefault();

            if (!this.enabledScroll) return;

            if(isCancel){
                event.preventDefault();
                return;
            }

            if (delta > 0) {
                this.forwardScroll();
            } else {
                this.backwardScroll();
            }
        }
    }

    touchStartHandler(event) {
        let touch = event.changedTouches[0];
        this.startTouchStartY = touch.clientY;
    }

    touchMoveHandler(event) {
        if(this.pack.header.isOpenMenu) {
            event.preventDefault();
            return;
        }

        let touch = event.changedTouches[0];

        if (this.lockScroll) {
            event.preventDefault();

            if (!this.enabledScroll) return;

            if (touch.clientY < this.startTouchStartY){
                this.forwardScroll();
            }else{
                this.backwardScroll();
            }
        }
   }

/*
    touchEndHandler(event) {

    }
*/

    forwardScroll() {
        this.enabledScroll = false;
        this.pastContents = this.currentContents;
        this.currentContents++;
        if (this.currentContents > this.contentsLen - 1) {
            this.currentContents = this.contentsLen - 1;
            this.enabledScroll = true;
        }
        else this.transit(this.currentContents);
    }

    backwardScroll() {
        this.enabledScroll = false;
        this.pastContents = this.currentContents;
        this.currentContents--;
        if (this.currentContents < 0) {
            this.currentContents = 0;
            this.enabledScroll = true;
        }
        else this.transit(this.currentContents);
    }

    transit(id){
        // trace('transit', id);
        this.hero.moveToScene(id);

        if(id === 2 || id === 3){
            this.cover.classList.add('show');
            this.pack.header.setWhite(true);
            this.pack.footer.setWhite(true);
            if(id === 2){
                this.cover.classList.remove('green');
            }else if(id === 3){
                this.cover.classList.add('green');
            }
        }else{
            this.cover.classList.remove('show');
            this.pack.header.setWhite(false);
            this.pack.footer.setWhite(false);
        }
        this.resetHeroImgContainer();

        if(id === 1) {
            this.heroImgContainer.classList.add("patern2");
        }
        else if(id === 2) {
            this.heroImgContainer.classList.add("patern3");
        }
        else if(id === 3) {
            this.heroImgContainer.classList.add("patern4");
        }
        else if(id === 4) {
            this.heroImgContainer.classList.add("patern5");
        }
        else if(id === 5) {
            this.heroImgContainer.classList.add("patern6");
        }

        TweenMax.delayedCall(.3, ()=>{
            let section = this['section' + (this.currentContents + 1)];
            if(!section.classList.contains('show')) section.classList.add('show');
        });

        //for scroll
        if(this.currentContents === 1){
            this.pack.anchorScroll(window.pageYOffset + this.section2.getBoundingClientRect().top, 1, Quart.easeInOut, this.onScrollEnd, this);
        }else if(this.currentContents === 2){
            this.pack.anchorScroll(window.pageYOffset + this.section3.getBoundingClientRect().top, 1, Quart.easeInOut, this.onScrollEnd, this);
        }else if(this.currentContents === 3){
            this.pack.anchorScroll(window.pageYOffset + this.section5.getBoundingClientRect().top - this.sh, 1, Quart.easeInOut, this.onScrollEnd, this);
        }else if(this.currentContents === 4){
            if(this.pastContents === 5) this.pack.anchorScroll(window.pageYOffset + this.section6.getBoundingClientRect().top - this.sh, 1, Quart.easeInOut, this.onScrollEnd, this);
            else this.pack.anchorScroll(window.pageYOffset + this.section5.getBoundingClientRect().top, 1, Quart.easeInOut, this.onScrollEnd, this);
        }else if(this.currentContents === 5){
            this.pack.anchorScroll(window.pageYOffset + this.section6.getBoundingClientRect().top, 1, Quart.easeInOut, this.onScrollEnd, this);
        }else{
            this.pack.anchorScroll(this.sh * this.currentContents, 1, Quart.easeInOut, this.onScrollEnd, this);
        }
    }

    onScrollEnd() {
        this.enabledScroll = true;

        if (this.currentContents === 0) this.lockScroll = true;
        else if (this.currentContents === 1) this.lockScroll = true;
        else if (this.currentContents === 2) this.lockScroll = false;
        else if (this.currentContents === 3) this.lockScroll = false;
        else if (this.currentContents === 4) {
            if (this.sw < this.pack.BP) this.lockScroll = false;
            else this.lockScroll = true;
        }
        else if (this.currentContents === 5) this.lockScroll = false;

        this.hasScrollBar(!this.lockScroll);
    }

    hasScrollBar(bool){
        if(bool) document.documentElement.classList.add('has_scroll_bar');
        else document.documentElement.classList.remove('has_scroll_bar');
    }

    resetHeroImgContainer(){
        for( let i = 2, len = 6; i <= len; i++ ){
            this.heroImgContainer.classList.remove("patern" + i);
        }
    }

    onLoadHero(event){
        this.start();
    }

    start(){
        document.body.classList.add('show');
        TweenMax.delayedCall(.1, ()=>{
            this.intro.classList.add("start");
            TweenMax.delayedCall(2.3, ()=>{
                this.pack.header.show();
                this.pack.footer.show();
                this.hero.start();
                this.heroImgContainer.classList.add('show');
                this.intro.classList.add("hide");
                TweenMax.delayedCall(1, ()=>{
                    this.section1.classList.add('show');
                    this.enabledScroll = true;
                    TweenMax.delayedCall(1.8, ()=>{
                        this.heroImgContainer.classList.add('remove_delay');
                    });
                });
            });
        });
    }

    scrollHandler(){

    }


    enterframe(){

    }

    enterframeThinOut(){
        this.hero.enterframeThinOut();
        this.carousel.enterframeThinOut();
        // trace('enterframeThinOut', this.currentContents, this.lockScroll);
        if (!this.lockScroll) {
            if(this.currentContents === 2 || this.currentContents === 3){
                let top3 = this.section3.getBoundingClientRect().top;
                let top4 = this.section4.getBoundingClientRect().top;
                let top5 = this.section5.getBoundingClientRect().top;
                if (top3 > 0) {
                    this.lockScroll = true;
                    this.currentContents = 2;
                    this.backwardScroll();
                    return;
                }

                if(top4 < this.shh) {
                    this.currentContents = 3;
                    let section = this['section' + (this.currentContents + 1)];
                    let ul = this['section' + (this.currentContents + 1) + 'UL'];
                    if(!section.classList.contains('show')) section.classList.add('show');

                    if(!ul.classList.contains('show')){
                        if(ul.getBoundingClientRect().top < this.shh + 200){
                            section.classList.add('show2');
                        }
                    }

                    if(!this.heroImgContainer.classList.contains("patern4")){
                        this.resetHeroImgContainer();
                        this.heroImgContainer.classList.add("patern4");
                        this.hero.moveToScene(this.currentContents);
                        this.cover.classList.add('green');
                    }
                }else {
                    this.currentContents = 2;
                    let section = this['section' + (this.currentContents + 1)];
                    let ul = this['section' + (this.currentContents + 1) + 'UL'];
                    if(!section.classList.contains('show')) section.classList.add('show');

                    if(!ul.classList.contains('show')){
                        if(ul.getBoundingClientRect().top < this.shh + 200){
                            section.classList.add('show2');
                        }
                    }

                    if(!this.heroImgContainer.classList.contains("patern3")){
                        this.resetHeroImgContainer();
                        this.heroImgContainer.classList.add("patern3");
                        this.hero.moveToScene(this.currentContents);
                        this.cover.classList.remove('green');
                    }
                }

                if (top5 < this.sh) {
                    this.lockScroll = true;
                    this.currentContents = 3;
                    this.forwardScroll();
                }
            }else if(this.currentContents === 4){
                let top = this.section5.getBoundingClientRect().top;
                if(this.sw < this.pack.BP){
                    let top2 = this.section6.getBoundingClientRect().top;
                    if(top > 1) {
                        this.lockScroll = true;
                        this.backwardScroll();
                    }else if(top2 < this.sh){
                        this.lockScroll = true;
                        this.forwardScroll();
                    }
                }else{
                    if(top < this.sh - 100) this.cover.classList.remove('show');
                    else this.cover.classList.add('show');
                }
            }else if(this.currentContents === 5){
                let top = this.section6.getBoundingClientRect().top;
                if(top > 1) {
                    this.lockScroll = true;
                    this.backwardScroll();
                }
            }
        }
    }

    executeResize() {
        super.executeResize();

        if(this.currentContents === 1) window.scrollTo(0, this.sh);
        else if(this.currentContents === 2) window.scrollTo(0, this.sh * 2);
        else if(this.currentContents === 4) window.scrollTo(0, window.pageYOffset + this.section5.getBoundingClientRect().top);
        else if(this.currentContents === 5) window.scrollTo(0, window.pageYOffset + this.section6.getBoundingClientRect().top);
    }
}