import TemplateContents from './TemplateContents';

export default class extends TemplateContents {
    constructor(param){
        super(param);
    }

    //ランディング時
    init() {
        // super.init();
        this.sw, this.sh;
        this.bindResizeHandler = this.resizeHandler.bind(this);
        this.hasTouch = ("ontouchstart" in window);
        this.resizeEvent = navigator.userAgent.match(/(iPhone|iPod|iPad)/) ? 'orientationchange' : 'resize';
    }

    setTarget(target) {
        this.target = target;

        this.setVars();
        this.setDom();
        this.initEvents();
    }

    setOnlyPC() {
        this.onlyPC = true;
    }

    setOnlySP() {
        this.onlySP = true;
    }

    setMarginRightPC(isVW, value) {
        this.hasMarginVWRightPC = isVW;
        if (isVW) this.marginVWRightPC = value;
        else this.marginRightPC = value;
        this.resizeHandler();
    }

    setMarginRightSP(isVW, value) {
        this.hasMarginVWRightSP = isVW;
        if (isVW) this.marginVWRightSP = value;
        else this.marginRightSP = value;
        this.resizeHandler();
    }

    setAdjustMinXPC(isVW, value) {
        this.hasMinXAdjustVWPC = isVW;
        if (isVW) this.minXAdjustVWPC = value;
        else this.minXAdjustPC = value;
        this.resizeHandler();
    }

    setAdjustMinXSP(isVW, value) {
        this.hasMinXAdjustVWSP = isVW;
        if (isVW) this.minXAdjustVWSP = value;
        else this.minXAdjustSP = value;
        this.resizeHandler();
    }

    //他ページから画面遷移で来た時(今回は使わない)
    reset() {
        // super.reset();
        // this.setVars();
    }

    //他ページに移動する時
    destruct() {
        super.destruct();
        this.carousel.removeEventListener("mouseover", this.bindMouseOverHandler);
        this.carousel.removeEventListener("mouseout", this.bindMouseOutHandler);
        this.carouselWrap.removeEventListener("mousemove", this.bindMouseMoveHandler);
        this.carousel.removeEventListener(this.swipeStartEvent, this.bindStartSwipeHandler);
        this.carousel.removeEventListener(this.swipeStopEvent, this.bindStopSwipeHandler);

        for (let i = 0, len = this.anchors.length; i < len; i++) {
            let item = this.anchors[i];
            item.removeEventListener("click", this.bindClickAnchorHandler);
        }

        if (this.next) this.next.removeEventListener("click", this.bindClickNextHandler);
        if (this.prev) this.prev.removeEventListener("click", this.bindClickPrevHandler);

        if (this.fader) {
            this.fader.removeEventListener(this.swipeStartEvent, this.bindStartSwipeFaderHandler);
            this.fader.removeEventListener(this.swipeStopEvent, this.bindStopSwipeFaderHandler);
            this.fader.removeEventListener("mouseover", this.bindMouseOverFaderHandler);
            this.fader.removeEventListener("mouseout", this.bindMouseOutFaderHandler);
        }
    }

    //変数設定
    setVars() {
        super.setVars();
        this.swipeIntervalCnt = 0;
        this.swiped = false;
        this.swipeInterval;
        this.swipeStartAbsoluteX;
        this.swipeStartPoint = { x: 0, y: 0 };
        this.pastSwipeX = 0;
        this.swipeX = 0;
        this.targetSwipeX = 0;
        this.pastTargetSwipeX = 0;

        this.isSwipingFader = false;
        this.swipeStartAbsoluteFaderX;
        this.swipeStartFaderPoint = { x: 0, y: 0 };
        this.pastSwipeFaderX = 0;
        this.targetSwipeFaderX = 0;

        this.enabled = false;
        this.marginRightPC = 0;
        this.marginRightSP = 0;
        this.hasMarginVWRightPC = false;
        this.hasMarginVWRightSP = false;
        this.marginVWRightPC = 0;
        this.marginVWRightSP = 0;

        this.minXAdjustPC = 0;
        this.minXAdjustSP = 0;
        this.hasMinXAdjustVWPC = false;
        this.hasMinXAdjustVWSP = false;
        this.minXAdjustVWPC = 0;
        this.minXAdjustVWSP = 0;

        this.faderAdjustWPC = 43;
        this.faderAdjustWSP = 40;
    }

    //dom設定
    setDom() {
        super.setDom();
        this.carouselWrap = this.target;
        this.carousel = this.target.querySelector('.carousel');
        this.carouselChilds = this.target.querySelectorAll('.carousel .carousel_item');
        this.carouselChildsImgs = this.target.querySelectorAll('.carousel .carousel_item img');
        this.anchors = this.target.querySelectorAll('.carousel .carousel_item a');
        this.prev = this.target.querySelector('.carousel_ui .prev_bt');
        this.next = this.target.querySelector('.carousel_ui .next_bt');
        this.barBase = this.target.querySelector('.carousel_ui .bar_base');
        this.bar = this.target.querySelector('.carousel_ui .bar_base .bar');
        this.fader = this.target.querySelector('.carousel_ui .fader');
        this.current = this.target.querySelector('.carousel_ui .current');
    }

    //イベント
    initEvents() {
        super.initEvents();

        this.swipingEvent = (this.pack.hasTouch) ? "touchmove" : "mousemove";
        this.swipeStartEvent = (this.pack.hasTouch) ? "touchstart" : "mousedown";
        this.swipeStopEvent = (this.pack.hasTouch) ? "touchend" : "mouseup";

        this.bindMouseOverHandler = this.mouseOverHandler.bind(this);
        this.bindMouseOutHandler = this.mouseOutHandler.bind(this);
        this.bindMouseMoveHandler = this.mouseMoveHandler.bind(this);
        this.bindStartSwipeHandler = this.startSwipeHandler.bind(this);
        this.bindSwipingHandler = this.swipingHandler.bind(this);
        this.bindStopSwipeHandler = this.stopSwipeHandler.bind(this);
        this.bindClickAnchorHandler = this.clickAnchorHandler.bind(this);
        this.bindClickNextHandler = this.clickNextHandler.bind(this);
        this.bindClickPrevHandler = this.clickPrevHandler.bind(this);
        this.bindStartSwipeFaderHandler = this.startSwipeFaderHandler.bind(this);
        this.bindSwipingFaderHandler = this.swipingFaderHandler.bind(this);
        this.bindStopSwipeFaderHandler = this.stopSwipeFaderHandler.bind(this);
        this.bindMouseOverFaderHandler = this.mouseOverFaderHandler.bind(this);
        this.bindMouseOutFaderHandler = this.mouseOutFaderHandler.bind(this);

        this.carousel.addEventListener("mouseover", this.bindMouseOverHandler);
        this.carousel.addEventListener("mouseout", this.bindMouseOutHandler);
        this.carouselWrap.addEventListener("mousemove", this.bindMouseMoveHandler);
        this.carousel.addEventListener(this.swipeStartEvent, this.bindStartSwipeHandler);
        this.carousel.addEventListener(this.swipeStopEvent, this.bindStopSwipeHandler);

        for (let i = 0, len = this.anchors.length; i < len; i++) {
            let item = this.anchors[i];
            item.addEventListener("click", this.bindClickAnchorHandler);
        }

        if (this.next) this.next.addEventListener("click", this.bindClickNextHandler);
        if (this.prev) this.prev.addEventListener("click", this.bindClickPrevHandler);

        if(this.fader){
            this.fader.addEventListener(this.swipeStartEvent, this.bindStartSwipeFaderHandler);
            this.fader.addEventListener(this.swipeStopEvent, this.bindStopSwipeFaderHandler);
            this.fader.addEventListener("mouseover", this.bindMouseOverFaderHandler);
            this.fader.addEventListener("mouseout", this.bindMouseOutFaderHandler);
        }
    }

    mouseOverFaderHandler(event) {
        this.fader.classList.add("hover");
    }

    mouseOutFaderHandler(event) {
        if (!this.isSwipingFader) this.fader.classList.remove("hover");
    }

    startSwipeFaderHandler(event) {
        this.resizeHandler();

        if (!this.enabled) return;
        this.isSwipingFader = true;
        let x;
        let hasTouch = event.type.indexOf('touch') == 0;

        if (hasTouch) {
            let touches = event.changedTouches[0];
            this.swipeStartAbsoluteFaderX = x = touches.clientX;
        } else {
            event.preventDefault();
            this.swipeStartAbsoluteFaderX = x = event.clientX;
        }

        this.swipeStartFaderPoint.x = x - this.pastSwipeFaderX;

        document.addEventListener(this.swipeStopEvent, this.bindStopSwipeFaderHandler);
        document.addEventListener(this.swipingEvent, this.bindSwipingFaderHandler);
    }

    swipingFaderHandler(event) {
        if (!this.enabled) return;
        let x;
        let hasTouch = event.type.indexOf('touch') == 0;
        let faderAdjustW;
        if(this.sw >= this.pack.BP) faderAdjustW = this.faderAdjustWPC;
        else faderAdjustW = this.faderAdjustWSP;

        let barBaseWidth = this.barBase.clientWidth - faderAdjustW;

        if (hasTouch) {
            let touches = event.changedTouches[0];
            this.swipeStartAbsoluteFaderX = x = touches.clientX;
        } else {
            event.preventDefault();
            this.swipeStartAbsoluteFaderX = x = event.clientX;
        }

        this.targetSwipeFaderX = (x - this.swipeStartFaderPoint.x);

        if (this.targetSwipeFaderX <= 0) this.targetSwipeFaderX = 0;
        else if (this.targetSwipeFaderX >= barBaseWidth) this.targetSwipeFaderX = barBaseWidth;

        // let scale = this.targetSwipeFaderX / barBaseWidth;
        // this.bar.style.transform = "scaleX(" + scale + ")";
        this.fader.style.transform = "translateX(" + (this.targetSwipeFaderX / this.sw) * 100 + "vw)";

        // if(this.current) this.updateCurrent(scale);
        this.updateContents();
    }

    stopSwipeFaderHandler(event) {
        this.pastSwipeFaderX = this.targetSwipeFaderX;
        this.isSwipingFader = false;
        this.fader.classList.remove("hover");
        document.removeEventListener(this.swipeStopEvent, this.bindStopSwipeFaderHandler);
        document.removeEventListener(this.swipingEvent, this.bindSwipingFaderHandler);
    }

    updateContents() {
        let faderAdjustW;
        if(this.sw >= this.pack.BP) faderAdjustW = this.faderAdjustWPC;
        else faderAdjustW = this.faderAdjustWSP;

        let uiMoveW = this.barBase.clientWidth - faderAdjustW;
        let contentsMoveW;
        let ratio;
        let dr = .9;

        if (this.sw >= this.pack.BP) contentsMoveW = this.getCarouselWidth() - this.sw + this.minXAdjustPC;
        else contentsMoveW = this.getCarouselWidth() - this.sw + this.minXAdjustSP;

        ratio = contentsMoveW / uiMoveW;

        this.targetSwipeX = -this.targetSwipeFaderX * ratio;
        this.pastSwipeX = this.targetSwipeX;

        TweenMax.killTweensOf(this);
        TweenMax.to(this, dr, {
            swipeX: this.targetSwipeX, onUpdate: () => {
                this.carousel.style.transform = 'translate3d(' + this.swipeX + 'px, 0, 0)';
            }, ease: Quart.easeOut
        });

        this.updateImages();
    }

    updateUI() {
        let faderAdjustW;
        if(this.sw >= this.pack.BP) faderAdjustW = this.faderAdjustWPC;
        else faderAdjustW = this.faderAdjustWSP;

        let barBaseWidth = this.barBase.clientWidth - faderAdjustW;
        let uiMoveW = barBaseWidth;
        let contentsMoveW;
        let ratio;
        if (this.sw >= this.pack.BP) contentsMoveW = this.getCarouselWidth() - this.sw + this.minXAdjustPC;
        else contentsMoveW = this.getCarouselWidth() - this.sw + this.minXAdjustSP;

        ratio = uiMoveW / contentsMoveW;

        this.targetSwipeFaderX = -this.targetSwipeX * ratio;
        this.pastSwipeFaderX = this.targetSwipeFaderX;
        // let scale = this.targetSwipeFaderX / barBaseWidth;
        // this.bar.style.transform = "scaleX(" + scale + ")";
        this.fader.style.transform = "translateX(" + (this.targetSwipeFaderX / this.sw) * 100 + "vw)";

        if(this.current) this.updateCurrent(scale);
    }

    updateCurrent(scale){
        let len = this.carouselChilds.length;
        let single = 1 / len;
        let current = Math.ceil(scale/single);
        if(current === 0) current = 1;
        else if(current > len) current = len;

        this.current.innerText = this.pack.addZero(String(current));
    }

    mouseMoveHandler(event) {
        if (!this.enabled) return;
    }

    mouseOverHandler(event) {
        if (!this.enabled) return;
    }

    mouseOutHandler(event) {
        if (!this.enabled) return;
    }

    clickNextHandler(event) {
        if (!this.enabled) return;
        let dr = .9;
        let singleW = this.carouselChilds[0].clientWidth;
        if (this.sw >= this.pack.BP) singleW += this.marginRightPC;
        else singleW += this.marginRightSP;

        let minX;
        if (this.sw >= this.pack.BP) minX = -singleW * this.carouselChilds.length + this.sw - this.minXAdjustPC;
        else minX = -singleW * this.carouselChilds.length + this.sw - this.minXAdjustSP;

        let current = Math.ceil(this.targetSwipeX / singleW) - 1;

        this.pastSwipeX = this.targetSwipeX = current * singleW;

        if (this.targetSwipeX <= minX) this.pastSwipeX = this.targetSwipeX = minX;
        TweenMax.killTweensOf(this);
        TweenMax.to(this, dr, {
            swipeX: this.targetSwipeX, onUpdate: () => {
                this.carousel.style.transform = 'translate3d(' + this.swipeX + 'px, 0, 0)';
            }, ease: Quart.easeOut
        });
    }

    clickPrevHandler(event) {
        if (!this.enabled) return;
        let dr = .9;
        let singleW = this.carouselChilds[0].clientWidth;
        if (this.sw >= this.pack.BP) singleW += this.marginRightPC;
        else singleW += this.marginRightSP;

        let current = Math.floor(this.targetSwipeX/singleW) + 1;

        this.pastSwipeX = this.targetSwipeX = current * singleW;
        if (this.targetSwipeX >= 0) this.pastSwipeX = this.targetSwipeX = 0;
        TweenMax.killTweensOf(this);
        TweenMax.to(this, dr, {
            swipeX: this.targetSwipeX, onUpdate: () => {
                this.carousel.style.transform = 'translate3d(' + this.swipeX + 'px, 0, 0)';
            }, ease: Quart.easeOut
        });
    }

    clickAnchorHandler(event) {
        if (!this.enabled) return;
        //swipe処理とのバッティングを避ける処理
        if (this.swipeIntervalCnt > 3 && this.swiped) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    startSwipeHandler(event) {
        this.resizeHandler();

        if (!this.enabled) return;
        let x;
        let hasTouch = event.type.indexOf('touch') == 0;

        this.swipeIntervalCnt = 0;
        this.swipeInterval = setInterval(() => {
            this.swipeIntervalCnt++;
        }, 1000 / 30);

        if (hasTouch) {
            let touches = event.changedTouches[0];
            this.swipeStartAbsoluteX = touches.clientX;
            x = touches.clientX * 2;
        } else {
            event.preventDefault();
            this.swipeStartAbsoluteX = event.clientX;
            x = event.clientX * 2;
        }

        this.swipeStartPoint.x = x - this.pastSwipeX;

        document.addEventListener(this.swipeStopEvent, this.bindStopSwipeHandler);
        document.addEventListener(this.swipingEvent, this.bindSwipingHandler);
    }

    swipingHandler(event) {
        if (!this.enabled) return;
        let x;
        let hasTouch = event.type.indexOf('touch') == 0;
        let dr = .9;

        let minX;
        if (this.sw >= this.pack.BP) minX = -this.getCarouselWidth() + this.sw - this.minXAdjustPC;
        else minX = -this.getCarouselWidth() + this.sw - this.minXAdjustSP;

        if (hasTouch) {
            // event.preventDefault();
            let touches = event.changedTouches[0];
            x = touches.clientX * 2;
        } else {
            event.preventDefault();
            x = event.clientX * 2;
        }

        this.targetSwipeX = (x - this.swipeStartPoint.x);

        if (this.targetSwipeX <= minX) this.targetSwipeX = minX;
        else if (this.targetSwipeX >= 0) this.targetSwipeX = 0;

        TweenMax.killTweensOf(this);
        TweenMax.to(this, dr, {
            swipeX: this.targetSwipeX, onUpdate: () => {
                this.carousel.style.transform = 'translate3d(' + this.swipeX + 'px, 0, 0)';
            }, ease: Quart.easeOut
        });

        this.updateImages();
        if(this.fader) this.updateUI();
    }

    stopSwipeHandler(event) {
        if (!this.enabled) return;
        let x;
        let hasTouch = event.type.indexOf('touch') == 0;

        if (hasTouch) {
            let touches = event.changedTouches[0];
            x = touches.clientX * 2;
        } else {
            x = event.clientX * 2;
        }

        //スワイプしたかどうか
        if (this.swipeStartAbsoluteX === x) this.swiped = false;
        else this.swiped = true;

        this.pastSwipeX = this.targetSwipeX;
        clearInterval(this.swipeInterval);
        document.removeEventListener(this.swipeStopEvent, this.bindStopSwipeHandler);
        document.removeEventListener(this.swipingEvent, this.bindSwipingHandler);
    }

    updateImages() {
        if (this.sw < this.pack.BP) return;
        let dr = .9;
        let difPast = (this.targetSwipeX - this.pastTargetSwipeX);
        for (let i = 0, len = this.carouselChildsImgs.length; i < len; i++) {
            let item = this.carouselChildsImgs[i];
            TweenMax.killTweensOf(item);
            TweenMax.to(item, dr, { bezier: [{ x: difPast }, { x: 0 }], ease: Quart.easeOut });
        }

        this.pastTargetSwipeX = this.targetSwipeX;
    }

    //60fps
    enterframe() {
        if (!this.enabled) return;
    }

    //30fps
    enterframeThinOut() {

    }

    getCarouselWidth() {
        let w = 0;
        for (let i = 0, len = this.carouselChilds.length; i < len; i++) {
            let item = this.carouselChilds[i];
            let singleW = item.clientWidth;
            if (this.sw >= this.pack.BP) singleW += this.marginRightPC;
            else singleW += this.marginRightSP;
            w += singleW;
        }

        return w;
    }

    //リサイズ
    executeResize() {
        super.executeResize();

        let carouselWidth = this.getCarouselWidth();
        if (carouselWidth > this.sw) this.enabled = true;
        else this.enabled = false;

        if (this.sw >= this.pack.BP) {
            if (this.onlySP) this.enabled = false;
            else {

            }
        } else if (this.sw < this.pack.BP) {
            if (this.onlyPC) this.enabled = false;
            else {

            }
        }

        if (this.hasMarginVWRightPC) this.marginRightPC = this.sw * this.marginVWRightPC / 100;
        if (this.hasMarginVWRightSP) this.marginRightSP = this.sw * this.marginVWRightSP / 100;

        if (this.hasMinXAdjustVWPC) this.minXAdjustPC = this.sw * this.minXAdjustVWPC / 100;
        if (this.hasMinXAdjustVWSP) this.minXAdjustSP = this.sw * this.minXAdjustVWSP / 100;
    }
}