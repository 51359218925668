import {ua} from './libs/daijima/ua';

export default class {
    constructor(target, targetPackageName) {
        this.init(target, targetPackageName);
        return this.obj;
    }

    getTargetPackage(){
        return this.obj;
    }

    init(target, targetPackageName){
        let obj = target[targetPackageName] = target[targetPackageName] || {};
        this.obj = obj;
        obj.BP = 768;
        obj.ua = ua;
        obj.hasTouch = ("ontouchstart" in window);
        obj.pageYOffset = 0;
        obj.pastPageYOffset = 0;
        obj.scrollWrapper = document.querySelector('.scroll-wrapper');


        obj.getFormatDate = (date) => {
            let dateArray = String(new Date(date)).split(" ");
            return dateArray[1] + " " + dateArray[2] + ", " + dateArray[3];
        };

        // 3桁カンマ区切りとする
        obj.addComma = (num) => {
            var s = String(num).split('.');
            var ret = String(s[0]).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
            if (s.length > 1) {
                ret += '.' + s[1];
            }
            return ret;
        };

        obj.d2r = function(d){
            return d * Math.PI / 180;
        };

        obj.r2d = function(r){
            return r * 180 / Math.PI;
        };

        obj.random = function(min, max){
            return Math.random() * (max - min) + min;
        };

        obj.checkBG = (target) => {
            if(!obj.background) return;
            if(target.getBoundingClientRect().top <= obj.common.sh){
                obj.background.changeToFooter();
            }else{
                obj.background.changeToNormal();
            }
        };

        obj.getPCVW = (v)=>{
            return v / 1600 * 100;
        };

        obj.getSPVW = (v)=>{
            return v / 375 * 100;
        };


        obj.checkBottom = () => {
            return (obj.pageYOffset + 1) >= (document.body.clientHeight - obj.common.sh);
        };

        obj.lockHTML = ()=>{
            let html = document.documentElement;
            html.classList.add("hidden");
        };

        obj.unlockHTML = ()=>{
            let html = document.documentElement;
            html.classList.remove("hidden");
        };

        obj.anchorScroll = function(targetY, dr, ease, cb, sc){
            if(!dr) dr = 1;
            if(!ease) ease = Quart.easeInOut;
            let obj = {y:window.pageYOffset};
            TweenMax.killTweensOf(obj);

            TweenMax.to(obj, dr, {y:targetY, ease:ease, onUpdate:function(){
                window.scrollTo(0, obj.y);
            }, onComplete:function(){
                if (cb) cb.call(sc);
            }});
        };

        obj.commonParallax = function(item, targetY, defY, addCode, moveY){
            let top = item.getBoundingClientRect().top;
            if(defY === undefined) defY = 200;

            let speed = (item.dataset.speed != "undefined")  ? item.dataset.speed : "5";
            let difY = (top - targetY) / speed + defY;  //スタート地点はデフォルトでは200px下げる
            if(addCode == undefined) addCode = '';

            if(moveY){
                if(difY < defY - moveY) difY = defY - moveY;
            }

            if(top < targetY) {
                item.style.transform = 'translate3d(0,' + difY + 'px, 0)' + addCode;
                // item.style.top = difY + 'px';
            }
        };

        obj.addZero = function(str){
            if(str.length == 1) str = "0"+str;
            return str;
        };
    }
}
