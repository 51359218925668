import TemplateContents from './TemplateContents';

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { LinearFilter } from 'three/src/constants'

// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

// import GUI from 'lil-gui';

const ambientIntensity = 0.9,
    ambientColor = 0xFFFFFF,
    directionalIntensity = 0.39,
    directionalColor = 0xFFFFFF,
    directionalLightX = 100,
    directionalLightY = 0,
    directionalLightZ = 0.866,
    materialCommonColor = 0xdbf6ff,
    materialEarthColor = 0x92aec3;

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init(true);
        this.resizeEvent = 'resize';
        this.initEvents();

        // this.devicePixelRatio = window.devicePixelRatio || 1;
        // this.devicePixelRatio = Math.min(2, this.devicePixelRatio);

        this.canvasContainer = document.querySelector('main.top .hero .canvasContainer');
        this.canvas = document.createElement("canvas");
        this.canvasContainer.appendChild(this.canvas);
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.canvas.width = this.width;
        this.canvas.height = this.height;

        this.initShader();
        this.initWebGL();
        this.load();

        // this.setGUI();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();

        this.enabledRender = false;
        // this.CIRCLE_SMALL_IMG_PATH = '/assets/img/top/circle_small.png';
        // this.CIRCLE_BIG_IMG_PATH = '/assets/img/top/circle_big.png';
        // this.GLB_PATH = '/assets/3d/model.glb';
        // this.GLB_PATH = '/assets/3d/model-draco.glb';
        this.GLB_PATH = '/assets/3d/model-medium-draco.glb';

        this.originVector3 = new THREE.Vector3(0, 0, 0);
        this.mouseStage = new THREE.Vector2();
        this.commonMaterial;
        this.noiseMeshMaterial;
        this.earthMeshMaterial;
        this.isHiddenNoiseMeshMaterial = false;

        this.amount = 12;
        this.currentContents = 0;
        this.cameraPositionZPC = 25000;
        this.cameraPositionZSP = 30000;
    }

    setDom(){
        super.setDom();
        // this.speedSceneRotationX = 0;
        this.speedSceneRotationY = .0015;
        this.speedSceneRotationZ = .0005;
    }

    initEvents(){
        super.initEvents();

        this.bindPointermoveHandler = this.pointerMoveHandler.bind(this);
        //マウスオーバーとドラッグ処理
        document.addEventListener(this.pack.hasTouch ? 'touchmove' : 'mousemove', this.bindPointermoveHandler,{passive : false});
    }

    pointerMoveHandler(event){
        let x, y;
        let w = this.sw;
        let h = this.sh;

        if(event.type.indexOf('touch') == 0) {
            let touches = event.changedTouches[0];
            x = touches.clientX;
            y = touches.clientY;
        }else{
            x = event.clientX;
            y = event.clientY;
        }

        this.mouseStage.x = (x - (w / 2)) * 5;
        this.mouseStage.y = (-( y ) + (h / 2)) * 5;
    }

    //for debug
    setGUI(){
        const gui = new GUI();
        gui.width = 414;
        gui.left = 0;
        gui.domElement.style.zIndex = 2000;
        gui.close();

        const PROPS = {
            'sceneX' : 0,
            'sceneY' : 0,
            'sceneZ' : 0,
            'sceneScale' : 1,
            'materialCommonColor' : materialCommonColor,
            'materialEarthColor' : materialEarthColor,
        };

/*
        gui.add(PROPS, 'sceneX', -20000, 20000).onChange(() => {
            this.scene.position.x = PROPS.sceneX;
        });

        gui.add(PROPS, 'sceneY', -30000, 30000).onChange(() => {
            this.scene.position.y = PROPS.sceneY;
        });

        gui.add(PROPS, 'sceneZ', -20000, 20000).onChange(() => {
            this.scene.position.z = PROPS.sceneZ;
        });

        gui.add(PROPS, 'sceneScale', 1, 10).onChange(() => {
            this.scene.scale.x = PROPS.sceneScale;
            this.scene.scale.y = PROPS.sceneScale;
            this.scene.scale.z = PROPS.sceneScale;
        });
*/

        gui.addColor(PROPS, 'materialCommonColor').onChange(() => {
            this.commonMaterial.emissive.set(PROPS.materialCommonColor);
            this.noiseMeshMaterial.color.set(PROPS.materialCommonColor);
        });

        gui.addColor(PROPS, 'materialEarthColor').onChange(() => {
            this.earthMeshMaterial.color.set(PROPS.materialEarthColor);
        });
    }

    initShader() {
        this.vertexShaderSrc = `
        
        `;

        this.fragmentShaderSrc = `
        
        `;
    }

    initWebGL() {
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            alpha : true,
            antialias : false
        });
        // this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setPixelRatio(1);
        this.renderer.setSize(this.sw, this.sh);
        this.renderer.autoClear = false;

        this.scene = new THREE.Scene();

        this.fieldOfView = 45;
        this.stageHeight = (this.sw >= this.pack.BP) ? 900 : 1430;
        this.cameraZ = this.stageHeight / Math.tan(this.fieldOfView * Math.PI / 360) / 2;
        this.camera = new THREE.PerspectiveCamera(this.fieldOfView, this.sw / this.sh, 1, -this.cameraZ);
        this.camera.position.z = this.cameraZ;

        // 平行光源
        this.directionalLight = new THREE.DirectionalLight(directionalColor, directionalIntensity);
        this.directionalLight.position.set(directionalLightX, directionalLightY, directionalLightZ);
        this.scene.add(this.directionalLight);

        // 環境光源
        this.ambientLight = new THREE.AmbientLight(ambientColor, ambientIntensity);
        this.scene.add(this.ambientLight);

        // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        // this.controls.autoRotate = true;
        // this.controls.autoRotateSpeed = -2;
        // this.controls.enableZoom = false;
        // this.controls.enableRotate = false;
    }

    load(){
        this.loadGeom().then(() => {
            this.dispatchEvent('loaded', this);
            this.initMesh();
        });
    }

    loadTexture(path, name){
        let textureLoader = new THREE.TextureLoader();
        return new Promise((resolve) =>{
            textureLoader.load(path, (texture) => {
                texture.mapping = THREE.UVMapping;
                texture.flipY = false;
                texture.minFilter = LinearFilter;
                texture.magFilter = LinearFilter;
                this[name] = texture;

                return resolve();
            });
        });
    }

    loadGeom(){
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/assets/3d/draco_decoder/');

        const loader = new GLTFLoader();
        loader.setDRACOLoader(dracoLoader);

        return new Promise((resolve) =>{
            loader.load(
                this.GLB_PATH,
                // ロード完了時の処理
                (obj) => {
                    let scene = obj.scene;

                    scene.traverse((child) => {

                        if (child.isMesh) {
                            if(child.name === "Holo_Planet_noisemesh_01"){
                                let material = new THREE.MeshStandardMaterial();
                                material.onBeforeCompile = ( shader ) => {

                                    shader.uniforms.time = { value: 0 };
                                    shader.uniforms.amount = { value: 6 };

                                    shader.vertexShader = 'uniform float time;\n uniform float amount;\n' + shader.vertexShader;
                                    shader.vertexShader = shader.vertexShader.replace(
                                        '#include <begin_vertex>',
                                        [
                                            `float theta = sin( time + position.y ) / amount;`,
                                            'float c = cos( theta );',
                                            'mat3 m = mat3( c, 0, 0, 0, 1, 0, 0, 0, 1 );',
                                            'vec3 transformed = vec3( position ) * m;',
                                        ].join( '\n' )
                                    );

                                    this.shader = shader;
                                    material.userData.shader = shader;
                                };

                                material.transparent = true;
                                material.alphaToCoverage = true;
                                material.color.set(materialCommonColor);
                                material.needsUpdate = true;
                                child.material = material;
                                this.noiseMeshMaterial = material;
                            }else if(child.name === "Holo_Planet_mainland_dots_01"){
                                let material = new THREE.MeshStandardMaterial();
                                material.color.set(materialEarthColor);
                                material.needsUpdate = true;
                                child.material = material;
                                this.earthMeshMaterial = material;
                            }else{
                                let material = child.material;
                                material.emissive.set(materialCommonColor);
                                if(!this.commonMaterial) this.commonMaterial = material;
                            }
                        }
                    });

                    // テクスチャの設定
                    // let base = scene.getObjectByName('Holo_Planet_base_01');
                    // base.material.needsUpdate = true;
                    // base.material.map = texture;

                    // シーンへのモデルの追加
                    // let mainland = obj.getObjectByName("Holo_Planet_mainland_dots_01");
                    // obj.remove(mainland);

                    const box = new THREE.Box3().setFromObject(scene);
                    const size = box.getSize(new THREE.Vector3()).length();
                    const center = box.getCenter(new THREE.Vector3());

                    // this.controls.reset();

                    scene.position.x += (scene.position.x - center.x);
                    scene.position.y += (scene.position.y - center.y);
                    scene.position.z += (scene.position.z - center.z);
                    // this.controls.maxDistance = size * 10;
                    this.camera.near = size / 100;
                    this.camera.far = size * 100;
                    this.camera.updateProjectionMatrix();

                    if(this.sw >= this.pack.BP) this.camera.position.z = this.cameraPositionZPC;
                    else this.camera.position.z = this.cameraPositionZSP;

                    // scene.scale.set(.3,.3,.3);

                    // this.scene.add(scene);

                    this.scene.scale.set(0,0,0);
                    this.geom = scene;
                    // trace(this.geom);

                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    initMesh(){
        // let geo = new THREE.SphereBufferGeometry(6000);
        // const material = new THREE.MeshBasicMaterial( { color:0x92aec3, transparent:true, opacity: .2 } );
        // const sphere = new THREE.Mesh( geo, material );
        // this.scene.add( sphere );
    }

    start(){
        this.scene.add(this.geom);
        this.enabledRender = true;
        TweenMax.to(this.scene.scale, 1.5, {delay:.3, x:1, y:1, z:1, ease:Quart.easeOut});
    }

    moveToScene(id){
        if(id === this.currentContents) return;

        this.currentContents = id;

        let x, y, scale, dr = 1;

        if(id === 0){
            x = y = 0;
            scale = 1;
        }else if(id === 1){
            if(this.sw >= this.pack.BP){
                x = 10000;
                y = 660;
                scale = 1.7;
            }else{
                x = 6480;
                y = -1440;
                scale = 1;
            }
        }else if(id === 2){
            if(this.sw >= this.pack.BP){
                x = y = 0;
                scale = 2.1;
            }else{
                x = y = 0;
                scale = 1.7;
            }
        }else if(id === 3){
            if(this.sw >= this.pack.BP){
                x = y = 0;
                scale = 2.4;
            }else{
                x = y = 0;
                scale = 1.7;
            }
        }else if(id === 4){
            if(this.sw >= this.pack.BP){
                x = 0;
                y = -28000;
                scale = 4.2;
            }else{
                x = 0;
                y = -28000;
                scale = 3.7;
            }
        }else if(id === 5){
            if(this.sw >= this.pack.BP){
                x = 10000;
                y = -6120;
                scale = 1.8;

            }else{
                x = 8800;
                y = -6120;
                scale = 1.8;
            }
        }

        TweenMax.to(this.scene.scale, dr, {x:scale, y:scale, z:scale, ease:Quart.easeInOut});
        TweenMax.to(this.scene.position, dr, {x:x, y:y, z:0, ease:Quart.easeInOut});

        let addRotationX = Math.floor(Math.random() * 2 - 1) * this.pack.d2r(10 + Math.random() * 10);
        let addRotationY = Math.floor(Math.random() * 2 - 1) * this.pack.d2r(10 + Math.random() * 10);
        let addRotationZ = Math.floor(Math.random() * 2 - 1) * this.pack.d2r(10 + Math.random() * 10);
        TweenMax.to(this.scene.rotation, .5, {x:this.scene.rotation.x + addRotationX, y:this.scene.rotation.y + addRotationY, z:this.scene.rotation.z + addRotationZ, ease:Sine.easeInOut});


        if(id != 4) TweenMax.fromTo(this, dr, {amount:6}, {amount:18, ease:Power4.easeIn});

        this.changeNoiseMeshMaterial(id);
    }

    changeNoiseMeshMaterial(id){
        if(id === 4){
            if(!this.isHiddenNoiseMeshMaterial) {
                TweenMax.to(this.noiseMeshMaterial, 1, {opacity:0, ease:Quart.easeInOut});
                this.isHiddenNoiseMeshMaterial = true;
            }
        }else{
            if(this.isHiddenNoiseMeshMaterial) {
                TweenMax.to(this.noiseMeshMaterial, 1, {opacity:1, ease:Quart.easeInOut});
                this.isHiddenNoiseMeshMaterial = false;
            }
        }
    }

    setContent(){

    }

    scrollHandler(){

    }

    render(){
        // カメラの自動移動
        // this.camera.position.x = 1000 * Math.sin(Date.now()*.001);
        // this.camera.position.y = 500 * Math.sin(Date.now()*.001);
        // this.camera.position.z = 100 * Math.cos(Date.now()*.001);
        // this.camera.lookAt(new THREE.Vector3(0, 0, 0));
        this.renderer.render(this.scene, this.camera);
    }

    enterframe(){

    }

    enterframeThinOut(){

        // this.controls.update();

        if(!this.enabledRender) return;

        // this.scene.rotation.x += this.speedSceneRotationX;
        this.scene.rotation.y += this.speedSceneRotationY;
        this.scene.rotation.x += this.speedSceneRotationZ;

        this.camera.lookAt(this.originVector3);
        this.camera.position.x += (this.mouseStage.x - this.camera.position.x) * .03;
        this.camera.position.y += (this.mouseStage.y - this.camera.position.y) * .03;

        if(this.shader) {
            let time = performance.now() / 1000;
            this.shader.uniforms.time.value = time;
            this.shader.uniforms.amount.value = this.amount;
        }

        this.render();
    }

    executeResize() {
        super.executeResize();

        if(!this.canvas) return;

        this.canvas.width = this.sw;
        this.canvas.height = this.sh;

        if(!this.camera) return;

        if(this.sw >= this.pack.BP) this.camera.position.z = this.cameraPositionZPC;
        else this.camera.position.z = this.cameraPositionZSP;

        this.camera.aspect = this.sw / this.sh;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.sw, this.sh);

        // this.stageHeight = (this.sw >= this.pack.BP) ? 900 : 1430;
        // this.cameraZ = this.stageHeight / Math.tan(this.fieldOfView * Math.PI / 360) / 2;
        // this.camera.position.z = this.cameraZ;
    }
}