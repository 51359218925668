import TemplateContents from './TemplateContents';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();
    }

    setDom(){
        super.setDom();
        this.footerDom = document.querySelector('footer');
        this.menuBtns = document.querySelectorAll('footer ul li');
        this.pageTop = document.querySelector('footer .page_top');
    }

    initEvents(){
        super.initEvents();
        for( let i = 0, len = this.menuBtns.length; i < len; i++ ){
            let item = this.menuBtns[i];
            item.addEventListener('click', (event)=>{
                this.dispatchEvent('select', this, i);
            });
        }

        this.pageTop.addEventListener('click', this.clickPageTopHandler.bind(this));
    }

    clickPageTopHandler(event){
        // this.pack.anchorScroll(0, 1, undefined, this.onPagetop, this);
        this.pack.top.externalTransit(0);
    }


    onPagetop(){
        trace('onPageTop');
    }

    show(){
        this.footerDom.classList.add('show');
    }

    setWhite(bool){
        if(bool) this.footerDom.classList.add("white");
        else this.footerDom.classList.remove("white");
    }

    start(){

    }

    scrollHandler(){

    }


    enterframe(){

    }

    enterframeThinOut(){
        let footerTop = this.footerDom.getBoundingClientRect().top;
        if(footerTop < this.sh - 20){
            this.pack.header.setWhite2(true);
        }else{
            this.pack.header.setWhite2(false);
        }
    }

    executeResize() {
        super.executeResize();
    }
}